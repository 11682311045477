<template>
  <div class="table-responsive">
    <a-select
      :default-value="status"
      class="col-3 mr-3"
      @change="handleChange"
    >
      <a-select-option value="PENDING">
        PENDING
      </a-select-option>
      <a-select-option value="ACCEPTED">
        ACCEPTED
      </a-select-option>
      <a-select-option value="REJECTED">
        REJECTED
      </a-select-option>
    </a-select>
    <table class="table table-hover text-nowrap">
      <thead>
        <tr>
          <th>Company Name</th>
          <th>Registered On</th>
          <th>Processed On</th>
          <th>Status</th>
          <th
            colspan="4"
            class="text-center"
          >
            Service Requested
          </th>
          <th>Actions</th>
        </tr>
        <tr>
          <th />
          <th />
          <th />
          <th />
          <th>Certificate</th>
          <th>Authentication</th>
          <th>Verification</th>
          <th>Signing</th>
          <th />
        </tr>
      </thead>
      <tbody v-if="items && items">
        <tr
          v-for="item in items"
          :key="item.id"
        >
          <td>{{ item.company_name }}</td>
          <td>{{ getDate(item.created_on) }}</td>
          <td>{{ item.nt_present ? item.nt_present : '' }}</td>
          <td>{{ item.reg_status }}</td>

          <td v-if="item.reg_status === 'PENDING'">
            <div class="d-flex">
              <a
                class="a_action_icon bg-success mb-2 mr-2"
                @click="approve(item)"
              >
                <i
                  class="fa fa-check"
                  aria-hidden="true"
                />
              </a>

              <a
                class="a_action_icon bg-danger mb-2 mr-2"
                @click="reject(item)"
              >
                <i
                  class="fa fa-close"
                  aria-hidden="true"
                />
              </a>
            </div>
          </td>
          <td v-if="item.reg_status === 'ACCEPTED'">
            <a
              class="a_action_icon bg-success mb-2 mr-2"
            >
              <i
                class="fa fa-check"
                aria-hidden="true"
              />
            </a>
          </td>
          <td v-if="item.reg_status === 'REJECTED'">
            <a
              class="a_action_icon bg-danger mb-2 mr-2"
            >
              <i
                class="fa fa-close"
                aria-hidden="true"
              />
            </a>
          </td>


          <td v-if="item.reg_status === 'PENDING'">
            <div class="d-flex">
              <a
                class="a_action_icon bg-success mb-2 mr-2"
                @click="approve(item)"
              >
                <i
                  class="fa fa-check"
                  aria-hidden="true"
                />
              </a>

              <a
                class="a_action_icon bg-danger mb-2 mr-2"
                @click="reject(item)"
              >
                <i
                  class="fa fa-close"
                  aria-hidden="true"
                />
              </a>
            </div>
          </td>
          <td v-if="item.reg_status === 'ACCEPTED'">
            <a
              class="a_action_icon bg-success mb-2 mr-2"
            >
              <i
                class="fa fa-check"
                aria-hidden="true"
              />
            </a>
          </td>
          <td v-if="item.reg_status === 'REJECTED'">
            <a
              class="a_action_icon bg-danger mb-2 mr-2"
            >
              <i
                class="fa fa-close"
                aria-hidden="true"
              />
            </a>
          </td>


          <td v-if="item.reg_status === 'PENDING'">
            <div class="d-flex">
              <a
                class="a_action_icon bg-success mb-2 mr-2"
                @click="approve(item)"
              >
                <i
                  class="fa fa-check"
                  aria-hidden="true"
                />
              </a>

              <a
                class="a_action_icon bg-danger mb-2 mr-2"
                @click="reject(item)"
              >
                <i
                  class="fa fa-close"
                  aria-hidden="true"
                />
              </a>
            </div>
          </td>
          <td v-if="item.reg_status === 'ACCEPTED'">
            <a
              class="a_action_icon bg-success mb-2 mr-2"
            >
              <i
                class="fa fa-check"
                aria-hidden="true"
              />
            </a>
          </td>
          <td v-if="item.reg_status === 'REJECTED'">
            <a
              class="a_action_icon bg-danger mb-2 mr-2"
            >
              <i
                class="fa fa-close"
                aria-hidden="true"
              />
            </a>
          </td>

          <td v-if="item.reg_status === 'PENDING'">
            <div class="d-flex">
              <a
                class="a_action_icon bg-success mb-2 mr-2"
                @click="approve(item)"
              >
                <i
                  class="fa fa-check"
                  aria-hidden="true"
                />
              </a>

              <a
                class="a_action_icon bg-danger mb-2 mr-2"
                @click="reject(item)"
              >
                <i
                  class="fa fa-close"
                  aria-hidden="true"
                />
              </a>
            </div>
          </td>
          <td v-if="item.reg_status === 'ACCEPTED'">
            <a
              class="a_action_icon bg-success mb-2 mr-2"
            >
              <i
                class="fa fa-check"
                aria-hidden="true"
              />
            </a>
          </td>
          <td v-if="item.reg_status === 'REJECTED'">
            <a
              class="a_action_icon bg-danger mb-2 mr-2"
            >
              <i
                class="fa fa-close"
                aria-hidden="true"
              />
            </a>
          </td>

          <a-modal
            v-model:visible="visible"
            title="Rejection Reason"
            @ok="reject(item)"
          >
            <a-input
              v-model="reason"
              @change="getValue($event)"
            />
          </a-modal>
          <td class="d-flex">
            <div
              v-if="item.reg_status === 'PENDING'"
              class="d-flex"
            >
              <a-tooltip
                placement="top"
              >
                <template #title>
                  Approve
                </template>
                <a
                  class="a_action_icon bg-success mb-2 mr-2"
                  @click="accept(item)"
                >
                  <i
                    class="fa fa-check"
                    aria-hidden="true"
                  />
                </a>
              </a-tooltip>
              <a-tooltip placement="top">
                <template #title>
                  Reject
                </template>
                <a
                  class="a_action_icon bg-secondary mb-2 mr-2"
                  @click="showModalVisible"
                >
                  <i
                    class="fa fa-close"
                    aria-hidden="true"
                  />
                </a>
              </a-tooltip>
              <a-tooltip
                placement="top"
              >
                <template #title>
                  View more
                </template>
                <a
                  class="a_action_icon bg-secondary mb-2 mr-2"
                  @click="view(item)"
                >
                  <i
                    class="fa fa-eye"
                    aria-hidden="true"
                  />
                </a>
              </a-tooltip>
            </div>
            <a-tooltip
              placement="top"
            >
              <template #title>
                View more
              </template>
              <a
                v-if="item.reg_status === 'ACCEPTED' || item.reg_status === 'REJECTED'"
                class="a_action_icon bg-secondary mb-2 mr-2"
                @click="view(item)"
              >
                <i
                  class="fa fa-eye"
                  aria-hidden="true"
                />
              </a>
            </a-tooltip>
          </td>
        </tr>
      </tbody>
    </table>
    <a-pagination
      v-if="total"
      :default-current="1"
      :total="total"
      @change="pagination($event)"
    />
  </div>
</template>

<script>
import {getRegisterActionType} from "../../store/adminRegister/adminRegister";
import moment from '@/plugins/moment'
import {notification} from "ant-design-vue";
import axios from '@/axios'
export default {
  name: "Registration",
  data() {
    return {
      step: 10,
      skip: 0,
      total: null,
      userID: null,
      service: null,
      visible: false,
      items: [],
      status: 'PENDING',
      reason: ''
    }
  },
  computed: {},
  mounted() {
    this.loadItems()
  },
  methods: {
    getValue(e) {
      this.reason = e.target.value
    },
    showModalVisible() {
      this.visible = !this.visible
    },
    handleChange(val) {
      this.status = val
      this.filteredByStatus()
    },
    approve (item){
      axios
          .post(`/admin/registration/${item.id}/approve`)
          .then((res) => {
            notification.success({
              message: 'Successfully Approve',
            })
            this.loadItems()
          })
          .catch(() => {})
    },
    loadItems() {
      const reg = {
        skip: this.skip,
        status: this.status
      }
      this.$store.dispatch(getRegisterActionType.getAdminRegisterAction, reg)
          .then(res => {
            this.items = res.data.data
            this.total = res.data.count > this.step ? res.data.count : null
          })
          .catch(err => {
            notification.error({
              message: err.message ? err.message : 'Failed, try again',
            })
          })
    },
    filteredByStatus() {
      const status = {
        status: this.status,
      }
      this.$store.dispatch(getRegisterActionType.filteredByStatusAction, status)
          .then(res => {
            this.items = res.data.data
            this.total = res.data.count > this.step ? res.data.count : null
          }).catch(() =>{})
    },
    pagination(int) {
      this.skip = 0
      this.skip += this.step * int - this.step
      this.loadItems()
    },
    getDate(date) {
      return moment(date)
    },
    accept(item) {
      axios
          .post(`/admin/registration/${item.id}/approve`)
          .then(() => {
            notification.success({
              message: 'Approved',
            })
            this.loadItems()
          })
    },
    reject(item) {
      const reason = {
        reason: this.reason
      }
      axios
          .post(`/admin/registration/${item.id}/reject`, reason)
          .then(() => {
            notification.success({
              message: 'Rejected',
            })
            this.visible = false
            this.loadItems()
          })
          .catch(() => {})
    },
    view(item) {
      var that = this;
      that.$router.push(`/registration/view-detail/${item.id}`)
    }
  }
}
</script>

<style scoped>
.a_action_icon {
  display: flex;
  justify-content: center;
  padding: 0;
}
</style>
